<template>
  <div>
    <el-card>
      <!-- <el-form ref="form" :inline="true">
        <el-form-item label="类型">
          <el-select v-model="queryForm.type" placeholder="请选择" clearable>
            <el-option
              :label="item.title"
              :value="item.title"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="toQuery(queryForm.type)"
            icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd">添加题库</el-button>
          <el-button type="primary" @click="getTypeList">题库分类</el-button>
        </el-form-item>
      </el-form> -->

      <div class="flex_box">
        <div class="flex-left">
          <!-- 主题分类列表弹框 -->
          <!-- <el-dialog
            title="主题分类列表"
            :visible.sync="TypeDialog"
            :center="true"
            width="60%"
            :close-on-click-modal="false"
          >
          </el-dialog> -->
          <div class="title_box">
            <div></div>
            <div class="hearingLibDialog_title">
              <el-button type="primary" @click="addType">添加分类</el-button>
            </div>
          </div>
          <div style="overflow:auto;height:700px">
            <el-table
              :data="typeList"
              class="table"
              ref="userTable.multipleSelection"
              header-cell-class-name="table-header"
              @row-click="selectQuestion"
              :highlight-current-row="true"
              border
            >
              <el-table-column
                prop="id"
                label="ID"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                label="主题"
                prop="title"
                align="center"
              ></el-table-column>
              <el-table-column
                label="第几关"
                prop="level"
                align="center"
              ></el-table-column>
              <el-table-column
                label="关联类型"
                prop="syntax_type"
                align="center"
              ></el-table-column>
              <el-table-column
                label="关联字母"
                prop="relative_words"
                align="center"
                width="75"
              ></el-table-column>

              <!-- <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editRadioType(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeRadioType(scope.row.id)"
                size="small"
              ></el-button>
            </template>
          </el-table-column> -->
            </el-table>
          </div>
        </div>
        <div class="flex-right">
          <div class="title_box">
            <div></div>
            <div class="hearingLibDialog_title">
              <el-button type="primary" @click="toAdd">添加题库</el-button>
            </div>
          </div>
          <div style="overflow:auto;height:700px">
            <el-table
              :data="subjectList"
              stripe
              class="table"
              ref="userTable.multipleSelection"
              header-cell-class-name="table-header"
              border
            >
              <!-- <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column> -->

              <el-table-column
                prop="id"
                label="ID"
                width="55"
                align="center"
              ></el-table-column>

              <el-table-column
                label="主题名称"
                prop="theme_name"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column label="题目名称" prop="title" align="center">
                <template slot-scope="scope">
                  <div style="max-height:200px" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column> -->

              <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    circle
                    @click="editGoods(scope.row)"
                    size="small"
                  ></el-button>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="removeTask(scope.row)"
                    size="small"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 分页区域 -->
      <!-- <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div> -->

      <!-- 添加题库 -->
      <el-dialog
        :title="addtitle"
        :visible.sync="addDialog"
        :center="true"
        width="60%"
        @close="closed"
        :close-on-click-modal="false"
      >
        <el-form :model="addForm" ref="addForm">
          <!-- <div style="display:flex">
            <el-form-item el-form-item label="题目主题" label-width="100px">
              <el-select
                v-model="addForm.theme_id"
                placeholder="请选择"
                clearable
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in typeList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <el-form-item el-form-item label="题目类型" label-width="100px">
            <el-select
              v-model="addForm.title_type"
              placeholder="请选择"
              clearable
            >
              <el-option
                :label="item.type"
                :value="item.id"
                v-for="item in questionTypeArr"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <template>
            <div>
              <el-form-item
                el-form-item
                label="题目名："
                label-width="100px"
                v-if="addForm.title_type == 'word'"
              >
                <el-input
                  type="textarea"
                  :rows="4"
                  v-model="addForm.title"
                  placeholder="请输入题目"
                />
              </el-form-item>

              <el-form-item
                el-form-item
                label="音频题目"
                label-width="100px"
                v-if="addForm.title_type == 'audio'"
              >
                <div v-if="audio_url">
                  <audio
                    :src="audio_url"
                    style="cursor: pointer;outline: none;"
                    controls="controls"
                    ref="audioPlay"
                  ></audio>
                </div>
                <el-button type="primary" @click="selectFiles">{{
                  isAdd == "add" ? "上传音频" : "修改音频"
                }}</el-button
                ><span class="file-name">{{ fileName }}</span>
                <el-progress
                  :percentage="audioProgress"
                  v-if="audioProgress"
                  style="width:400px;"
                ></el-progress>
                <input
                  type="file"
                  hidden
                  ref="files"
                  @change="changeFiles"
                  accept="audio/*"
                />
              </el-form-item>

              <el-form-item
                el-form-item
                label="视频题目"
                label-width="100px"
                v-if="addForm.title_type == 'video'"
              >
                <div v-if="video_url">
                  <video
                    :src="video_url"
                    style="width: 300px; height: 200px; cursor: pointer"
                    autoplay
                    v-if="video_url"
                    @click="selectVideo"
                    ref="videoPlay"
                  ></video>
                </div>
                <el-button type="primary" @click="selectVideo"
                  >选择视频文件</el-button
                ><span class="file-name">{{ fileVideoName }}</span>
                <el-progress
                  :percentage="videoProgress"
                  v-if="videoProgress"
                  style="width:400px;"
                ></el-progress>
                <input
                  type="file"
                  name="video"
                  id=""
                  hidden
                  ref="video"
                  @change="selectVideoFiles"
                  accept="video/*"
                />
              </el-form-item>

              <el-form-item
                el-form-item
                label="图片题目"
                label-width="100px"
                v-if="addForm.title_type == 'pic'"
              >
                <el-upload
                  class="avatar-uploader"
                  action="456"
                  :show-file-list="false"
                  accept="image/*"
                  :before-upload="beforeKatakanaUpload"
                >
                  <img
                    v-if="pic_url"
                    style="width: 100%"
                    :src="pic_url"
                    class="avatar"
                  />
                  <i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                    style="margin-top: 85px"
                  ></i>
                </el-upload>
              </el-form-item>

              <el-form-item el-form-item label="选项类型" label-width="100px">
                <el-select
                  v-model="addForm.options_type"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    :label="item.type"
                    :value="item.id"
                    v-for="item in optionsTypeArr"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                el-form-item
                label="添加选项："
                label-width="100px"
                v-if="addForm.options_type == 'word'"
              >
                <div class="addtopic">
                  <div></div>
                  <el-button
                    type="primary"
                    @click="addTopicOptions(isSelectOptions)"
                    >添加题目选项</el-button
                  >
                </div>
                <div
                  class="add_box"
                  v-for="(item, index) in addForm.lib[isSelectOptions].option"
                  :key="index"
                >
                  <div class="add_item">
                    {{ item.key }}
                    <el-input
                      v-model="item.value"
                      placeholder="请输入题目选项名"
                      style="margin:0 30px;width=100px"
                    ></el-input>
                  </div>
                  <div class="add_btn">
                    <el-button
                      v-if="item.key == 'D'"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeOptions(index)"
                      size="small"
                    ></el-button>
                  </div>
                </div>
              </el-form-item>

              <el-form-item
                el-form-item
                label="添加选项："
                label-width="100px"
                v-if="addForm.options_type == 'audio'"
              >
                <div class="addtopic">
                  <div></div>
                  <el-button
                    type="primary"
                    @click="addTopicOptions(isSelectOptions)"
                    >添加题目选项</el-button
                  >
                </div>
                <div
                  class="add_box_audio"
                  v-for="(item, index) in addForm.lib[isSelectOptions].option"
                  :key="index"
                >
                  <div class="add_item">
                    <div class="add_option">{{ item.key }}</div>
                    <div
                      v-if="options_audio_url[index]"
                      style="margin:0 15px;width=100px"
                    >
                      <audio
                        :src="options_audio_url[index]"
                        style="cursor: pointer;outline: none;display: flex;align-items: center;"
                        controls="controls"
                        ref="audioPlay"
                      ></audio>
                    </div>
                    <el-button
                      type="primary"
                      @click="selectOptionsFile(index)"
                      style="margin:0 30px;width=100px"
                      >{{ isAdd == "add" ? "上传音频" : "修改音频" }}</el-button
                    >
                    <div class="add_info">
                      <span class="file-name" v-if="item.optionsfileName">{{
                        item.optionsfileName
                      }}</span>
                      <el-progress
                        v-if="item.optionsProgress"
                        :percentage="item.optionsProgress"
                        style="width:200px;"
                      ></el-progress>
                    </div>
                    <!-- <span class="file-name" v-if="optionsfileName[index]">{{ optionsfileName[index] }}</span>
                          <el-progress :percentage="optionsProgress[clickAudio]" v-if="optionsProgress[index]"></el-progress> -->
                    <input
                      type="file"
                      hidden
                      ref="optionsFile"
                      @change="changeOptionsFiles"
                      accept="audio/*"
                    />
                  </div>
                  <div class="add_btn">
                    <el-button
                      v-if="item.key == 'D'"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeOptions(index)"
                      size="small"
                    ></el-button>
                  </div>
                </div>
              </el-form-item>

              <el-form-item el-form-item label="答案" label-width="100px">
                <el-select
                  v-model="addForm.lib[isSelectOptions].correct"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="item in optionsArr"
                    :key="item.key"
                  ></el-option>
                </el-select>
              </el-form-item>

              <!-- <el-form-item  el-form-item label="题目解析：" label-width="100px">
                <quill-editor
                  v-model="addForm.lib[isSelectOptions].analysis"
                  ref="myQuillEditor"
                >
                </quill-editor>
              </el-form-item> -->
            </div>
          </template>

          <el-form-item label-width="40%" style="margin-top: 20px;">
            <el-button type="primary" v-if="isAdd == 'add'" @click="addNow"
              >立即添加</el-button
            >
            <el-button type="primary" v-else @click="editReadNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 添加主题分类弹框 -->
      <el-dialog
        :title="addTypeTitle"
        :center="true"
        :visible.sync="addTypeDialog"
        width="40%"
        @close="addTypeClose"
        :close-on-click-modal="false"
      >
        <el-form :model="addTypeForm">
          <el-form-item label="主题名:" label-width="100px">
            <el-input v-model="addTypeForm.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="第几关:" label-width="100px">
            <el-input v-model="addTypeForm.level" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item el-form-item label="关联类型" label-width="100px">
            <el-select
              v-model="checkRelativeType"
              placeholder="请选择"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.id"
                v-for="item in alphabetTypeList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联字母" label-width="100px">
            <el-cascader
              v-model="checkRelativeWords"
              :options="relativeWordsArr"
              :props="{ multiple: true, checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label-width="40%">
            <el-button type="primary" v-if="isAddType" @click="addTypeNow"
              >立即添加</el-button
            >
            <el-button type="primary" v-else @click="editTypeNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

var COS = require("cos-js-sdk-v5");
import {
  addExamTheme,
  addExamTitle,
  themeListByType,
  titleListByThemeId,
  updateTitleById,
  delTitleById,
  examCosToken
} from "@/api/French/questionbank_management.js";
import {
  addLetter,
  updateLetter,
  getLetterList,
  getLetterDetail,
  getReadCosToken
} from "@/api/French/french_alphabet";
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      queryForm: {
        type: "",
        id: null,
        page: 1,
        limit: 10
        // start_time: null,
        // end_time: null,
      },
      gradeList: [],
      subjectType: [],
      subjectList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        title: "",
        theme_id: "",
        lib: [
          {
            correct: "",
            option: [
              { key: "A", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "B", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "C", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "D", value: "", optionsfileName: "", optionsProgress: 0 }
            ]
          }
        ],
        options_type: "",
        title_type: ""
      },
      optionsArr: [
        { value: "A", key: "A" },
        { value: "B", key: "B" },
        { value: "C", key: "C" },
        { value: "D", key: "D" }
      ],
      addtitle: "",
      isAdd: "add",
      fileName: "",
      progress: 0,
      audioProgress: 0,
      videoProgress: 0,
      subjectTypeData: null,
      addFormSubjectType: [],
      isSelectOptions: 0,
      topicItem: {},
      addTypeTitle: "",
      typeList: [],
      addTypeForm: {
        title: "",
        level: "",
        syntax_type: "",
        relative_letter_id: []
      },
      TypeDialog: false,
      addTypeDialog: false,
      isAddType: "add",
      questionTypeArr: [
        { id: "word", type: "文字" },
        { id: "audio", type: "音频" },
        { id: "pic", type: "图片" },
        { id: "video", type: "视频" }
      ],
      optionsTypeArr: [
        { id: "word", type: "文字" },
        { id: "audio", type: "音频" }
      ],
      // options_type: "",
      // title_type: "",
      audio_url: "",
      video_url: "",
      pic_url: "",
      questionId: null,
      fileVideoName: "",
      fileAudioName: "",
      filePicName: "",
      clickAudio: null,
      options_audio_url: ["", "", "", ""],
      option: {},
      relativeWordsArr: [],
      alphabetTypeList: [
        { id: 1, value: "字母" },
        { id: 2, value: "元音" },
        { id: 3, value: "辅音" }
      ],
      checkRelativeWords: [],
      checkRelativeType: null,
      selectThemeId: null
    };
  },
  created() {
    // this.getThemeList();
    this.getTypeList();
    // themeListByType().then(res => {
    //   res.data.forEach((item, index) => {
    //     item.relative_words = "";
    //     item.letter.forEach((items, indexs) => {
    //       if (indexs == 0) {
    //         item.relative_words = items.word;
    //       } else {
    //         item.relative_words = item.relative_words + "," + items.word;
    //       }
    //     });
    //   });
    //   this.typeList = res.data;
    //   console.log(this.typeList);
    //   this.queryForm.type = this.typeList[0].title;
    //   this.getQuestionList(this.typeList[0].id);
    // });
  },
  watch: {
    "queryForm.grade": {
      handler(newVal) {
        for (const key in this.subjectTypeData) {
          if (newVal == key) {
            this.subjectType = this.subjectTypeData[key];
          }
        }
      },
      deep: true
    },

    "addForm.grade": {
      handler(newVal) {
        for (const key in this.subjectTypeData) {
          if (newVal == key) {
            this.addFormSubjectType = this.subjectTypeData[key];
          }
        }
      },
      deep: true
    },
    checkRelativeType: {
      handler(newVal) {
        console.log(newVal);
        this.getAlphabetList({ type: newVal, size: 30 });
        if (newVal == 1) {
          this.addTypeForm.syntax_type = "french_letter";
        } else if (newVal == 2) {
          this.addTypeForm.syntax_type = "french_vowel";
        } else if (newVal == 3) {
          this.addTypeForm.syntax_type = "french_consonant";
        }
        console.log(this.addTypeForm.syntax_type);
      },
      deep: true
    },
    checkRelativeWords: {
      handler(newVal) {
        this.addTypeForm.relative_letter_id = [];
        newVal.forEach((item, index) => {
          this.addTypeForm.relative_letter_id.push(item[0]);
        });
      },
      deep: true
    }
  },
  methods: {
    selectQuestion(row) {
      this.selectThemeId = row.id;
      this.getQuestionList(row.id);
    },
    // 删除小题目选项
    removeOptions(index) {
      if (this.addForm.lib[this.isSelectOptions].option.length <= 3) {
        this.$message.error("选项不少于3项");
        return;
      }
      this.addForm.lib[this.isSelectOptions].option.splice(index, 1);
    },
    //点击题库分类打开弹窗
    getTypeList() {
      this.TypeDialog = true;
      this.getThemeList();
    },
    addType() {
      this.addTypeDialog = true;
      this.addTypeTitle = "添加分类";
    },
    addTypeClose() {
      this.checkRelativeType = null;
      this.addTypeForm.title = "";
      this.addTypeForm.level = "";
      this.addTypeForm.relative_letter_id = [];
      this.relativeWordsArr = [];
      this.checkRelativeWords = [];
    },
    //获取字母列表
    getAlphabetList(type) {
      getLetterList(type).then(res => {
        console.log(res);
        this.relativeWordsArr = [];
        res.data.forEach((item, index) => {
          let obj = {};
          obj.value = item.id;
          obj.label = item.word;
          this.relativeWordsArr.push(obj);
        });
        console.log(this.relativeWordsArr);
      });
    },
    addTypeNow() {
      if (this.addTypeForm.title == "") {
        this.$message.error("请输入主题名");
        return;
      } else if (this.addTypeForm.level == "") {
        this.$message.error("请输入第几关");
        return;
      } else if (this.addTypeForm.syntax_type == "") {
        this.$message.error("请选择关联类型");
        return;
      } else if (this.addTypeForm.relative_letter_id.length == 0) {
        this.$message.error("请选择关联字母/元音/辅音");
        return;
      }
      console.log(this.addTypeForm);
      addExamTheme(this.addTypeForm).then(res => {
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.addTypeDialog = false;
        this.$message.success("添加成功!");
        this.getThemeList();
      });
    },
    //获取主题列表
    getThemeList() {
      themeListByType().then(res => {
        console.log(res);
        res.data.forEach((item, index) => {
          item.relative_words = "";
          if (item.syntax_type == "french_letter") {
            item.syntax_type = "字母";
          } else if (item.syntax_type == "french_vowel") {
            item.syntax_type = "元音";
          } else if (item.syntax_type == "french_consonant") {
            item.syntax_type = "辅音";
          }
          item.letter.forEach((items, indexs) => {
            if (indexs == 0) {
              item.relative_words = items.word;
            } else {
              item.relative_words = item.relative_words + "," + items.word;
            }
          });
        });
        this.typeList = res.data;
        console.log(this.typeList);
      });
    },
    editTypeNow() {},
    // 选中选项tab
    selectOption(index) {
      this.isSelectOptions = index;
    },
    // 点击添加题目按钮
    addSubject() {
      this.addForm.lib.push({
        topic_lib: "",
        correct: "",
        option: [
          { key: "A", value: "" },
          { key: "B", value: "" },
          { key: "C", value: "" },
          { key: "D", value: "" }
        ],
        analysis: ""
      });
      this.isSelectOptions = this.addForm.lib.length - 1;
    },
    // 删除题库
    async removeTask(row) {
      // console.log(row)
      const result = await this.$confirm("是否要删除该题库?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      delTitleById({ id: row.id }).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除题库成功");
        this.getQuestionList(row.theme_id);
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加题库";
      this.addDialog = true;
    },

    // 添加题目选项
    addTopicOptions(i) {
      if (this.addForm.lib[i].option.length >= 4) {
        this.$message.error("选项不能多于4项");
        return;
      }
      this.addForm.lib[i].option.push({ key: "D", value: "" });
    },
    // 点击选择音频选项
    selectOptionsFile(index) {
      this.$refs.optionsFile[index].click();
      this.clickAudio = index;
      console.log(this.clickAudio);
    },

    // 选中音频选项文件
    changeOptionsFiles(e) {
      let file = this.$refs.optionsFile[this.clickAudio].files[0];
      this.addForm.lib[0].option[this.clickAudio].optionsfileName = file.name;
      // console.log(this.optionsfileName)
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      examCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              console.log(that.clickAudio);
              that.addForm.lib[0].option[that.clickAudio].optionsProgress =
                progressData.percent * 100;
              console.log(
                that.addForm.lib[0].option[that.clickAudio].optionsProgress
              );
            }
          },
          function(err, data) {
            if (data) {
              console.log(that.clickAudio);
              that.addForm.lib[0].option[that.clickAudio].value = res.data.key;
              console.log(that.addForm.lib[0].option[that.clickAudio].value);
            }
            e.target.value = "";
          }
        );
      });
    },

    // 点击选择音频按钮
    selectFiles() {
      this.$refs.files.click();
    },

    // 选中音频文件
    changeFiles(e) {
      let file = this.$refs.files.files[0];
      this.fileName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      examCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.audioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              that.addForm.title = res.data.key;
              console.log(that.addForm.title);
            }
            e.target.value = "";
          }
        );
      });
    },

    // 添加题库
    addNow() {
      if (this.addForm.title_type == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addForm.options_type == "") {
        this.$message.error("请输入选项类型");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[0].value == "") {
        this.$message.error("请输入A选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[1].value == "") {
        this.$message.error("请输入B选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[2].value == "") {
        this.$message.error("请输入C选项");
        return;
      } else if (
        this.addForm.lib[this.isSelectOptions].option.length == 4 &&
        this.addForm.lib[this.isSelectOptions].option[3].value == ""
      ) {
        this.$message.error("请输入D选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].correct == "") {
        this.$message.error("请输入正确答案");
        return;
      }
      // console.log(this.addForm);
      let option = {};
      this.addForm.lib[0].option.forEach((item, index) => {
        if (item.key == "A") {
          option.A = item.value;
        } else if (item.key == "B") {
          option.B = item.value;
        } else if (item.key == "C") {
          option.C = item.value;
        } else if (item.key == "D") {
          option.D = item.value;
        }
      });
      // option = JSON.stringify(option);
      // if(typeof(option) == "object" && Object.prototype.toString.call(option).toLowerCase() == "[object object]" && !option.length){
      //   console.log('是');
      // }else{
      //   console.log('不是')
      // }
      // console.log(option)
      let addData = {
        title: this.addForm.title,
        theme_id: this.selectThemeId,
        options: option,
        right: this.addForm.lib[0].correct,
        title_type: this.addForm.title_type,
        options_type: this.addForm.options_type
      };
      addExamTitle(addData).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加题库成功");
        this.addDialog = false;
        this.questionId = res.data;
        this.getQuestionList(addData.theme_id);
      });
    },
    //获取题目列表
    getQuestionList(id) {
      titleListByThemeId({ theme_id: id }).then(res => {
        res.data.forEach((item, index) => {
          this.typeList.forEach((items, indexs) => {
            if (item.theme_id == items.id) {
              item.theme_name = items.title;
            }
          });
        });
        this.subjectList = res.data;
        this.total = res.data.length;
      });
    },
    // 点击编辑图标
    editGoods(row) {
      console.log(row);
      this.isAdd = "edit";
      this.addtitle = "编辑题库";
      this.addDialog = true;
      let optionData = JSON.parse(row.options.replace(/[\\]/g, ""));
      // console.log(optionData);
      if (row.title_type == "audio") {
        this.audio_url = row.title;
      } else if (row.title_type == "video") {
        this.video_url = row.title;
      } else if (row.title_type == "pic") {
        this.pic_url = row.title;
      }
      this.addForm.title = row.title
        .split("?")[0]
        .replace("https://file.qiaxueedu.com/", "");
      console.log(this.addForm.title);
      this.addForm.lib[this.isSelectOptions].correct = row.right;
      this.addForm.theme_id = row.theme_id;
      this.addForm.title_type = row.title_type;
      this.addForm.options_type = row.options_type;
      this.addForm.id = row.id;
      if (optionData.D == undefined) {
        this.addForm.lib[this.isSelectOptions].option = [
          { key: "A", value: "", optionsfileName: "", optionsProgress: 0 },
          { key: "B", value: "", optionsfileName: "", optionsProgress: 0 },
          { key: "C", value: "", optionsfileName: "", optionsProgress: 0 }
        ];
        this.addForm.lib[this.isSelectOptions].option[0].value = JSON.parse(
          row.options
        ).A;
        this.addForm.lib[this.isSelectOptions].option[1].value = JSON.parse(
          row.options
        ).B;
        this.addForm.lib[this.isSelectOptions].option[2].value = JSON.parse(
          row.options
        ).C;
        this.addForm.lib[0].option.forEach((item, index) => {
          if (this.addForm.options_type == "audio") {
            if (item.key == "A") {
              this.options_audio_url[0] = item.value;
            } else if (item.key == "B") {
              this.options_audio_url[1] = item.value;
            } else if (item.key == "C") {
              this.options_audio_url[2] = item.value;
            }
          }
        });
      }
      this.addForm.lib[this.isSelectOptions].option[0].value = JSON.parse(
        row.options
      ).A;
      this.addForm.lib[this.isSelectOptions].option[1].value = JSON.parse(
        row.options
      ).B;
      this.addForm.lib[this.isSelectOptions].option[2].value = JSON.parse(
        row.options
      ).C;
      this.addForm.lib[this.isSelectOptions].option[3].value = JSON.parse(
        row.options
      ).D;
      this.addForm.lib[0].option.forEach((item, index) => {
        if (this.addForm.options_type == "audio") {
          if (item.key == "A") {
            this.options_audio_url[0] = item.value;
          } else if (item.key == "B") {
            this.options_audio_url[1] = item.value;
          } else if (item.key == "C") {
            this.options_audio_url[2] = item.value;
          } else if (item.key == "D") {
            this.options_audio_url[3] = item.value;
          }
        }
      });
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.theme_id == "") {
        this.$message.error("请选择主题");
        return;
      } else if (this.addForm.title_type == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addForm.options_type == "") {
        this.$message.error("请输入选项类型");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[0].value == "") {
        this.$message.error("请输入A选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[1].value == "") {
        this.$message.error("请输入B选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].option[2].value == "") {
        this.$message.error("请输入C选项");
        return;
      } else if (
        this.addForm.lib[this.isSelectOptions].option.length == 4 &&
        this.addForm.lib[this.isSelectOptions].option[3].value == ""
      ) {
        this.$message.error("请输入D选项");
        return;
      } else if (this.addForm.lib[this.isSelectOptions].correct == "") {
        this.$message.error("请输入正确答案");
        return;
      }
      this.addForm.lib[0].option.forEach((item, index) => {
        if (this.addForm.options_type == "word") {
          if (item.key == "A") {
            this.option.A = item.value;
          } else if (item.key == "B") {
            this.option.B = item.value;
          } else if (item.key == "C") {
            this.option.C = item.value;
          } else if (item.key == "D") {
            this.option.D = item.value;
          }
        } else if (this.addForm.options_type == "audio") {
          if (item.key == "A") {
            this.options_audio_url[0] = item.value;
            this.option.A = item.value
              .split("?")[0]
              .replace("https://file.qiaxueedu.com/", "");
          } else if (item.key == "B") {
            this.options_audio_url[1] = item.value;
            this.option.B = item.value
              .split("?")[0]
              .replace("https://file.qiaxueedu.com/", "");
          } else if (item.key == "C") {
            this.options_audio_url[2] = item.value;
            this.option.C = item.value
              .split("?")[0]
              .replace("https://file.qiaxueedu.com/", "");
          } else if (item.key == "D") {
            this.options_audio_url[3] = item.value;
            this.option.D = item.value
              .split("?")[0]
              .replace("https://file.qiaxueedu.com/", "");
          }
        }
      });
      let editData = {
        title: this.addForm.title,
        theme_id: this.addForm.theme_id,
        options: this.option,
        right: this.addForm.lib[0].correct,
        title_type: this.addForm.title_type,
        options_type: this.addForm.options_type,
        id: this.addForm.id
      };
      console.log(editData);
      updateTitleById(editData).then(res => {
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改成功!");
        this.addDialog = false;
        this.getQuestionList(editData.theme_id);
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        grade: "",
        type: "",
        topic: "",
        original: "",
        audio: "",
        lib: [
          {
            topic_lib: "",
            correct: "",
            option: [
              { key: "A", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "B", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "C", value: "", optionsfileName: "", optionsProgress: 0 },
              { key: "D", value: "", optionsfileName: "", optionsProgress: 0 }
            ],
            analysis: ""
          }
        ]
      };
      this.isSelectOptions = 0;
      this.fileName = "";
      // this.progress = 0;
      this.pic_url = "";
      (this.optionsfileName = ["", "", "", ""]),
        (this.options_audio_url = ["", "", "", ""]),
        (this.option = {});
      // if (this.$refs.audioPlay !== undefined) {
      //   this.$refs.audioPlay.pause();
      // }
      this.video_url = "";
      this.audio_url = "";
      this.fileVideoName = "";
      this.videoProgress = 0;
      this.audioProgress = 0;
      this.optionsProgress = [0, 0, 0, 0];
    },

    //  查询
    toQuery(type) {
      // this.queryForm.page = 1;
      // if (this.times) {
      //   this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
      //   this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      // } else if (this.times == null) {
      //   this.queryForm.start_time = null;
      //   this.queryForm.end_time = null;
      // }
      this.typeList.forEach((item, index) => {
        if (item.title == type) {
          this.queryForm.id = item.id;
        }
      });
      this.getQuestionList(this.queryForm.id);
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      console.log(this.addForm.theme_id);
      this.getQuestionList(1);
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getQuestionList(this.addForm.theme_id);
    },

    // 选择图片
    beforeMemoryUpload(file) {
      const isSize = file.size / 1024 / 1024 < 1;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 1MB!");
        return;
      }
      let that = this;
      examCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {}
          },
          function(err, data) {
            if (data) {
              that.editForm.hiragana_card = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = e => {
                that.editForm.hiragana_card_url = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    beforeKatakanaUpload(file) {
      // const isSize = file.size / 1024 / 1024 < 1;
      // if (!isSize) {
      //   this.$message.error("上传缩略图不能超过 1MB!");
      //   return;
      // }
      let that = this;
      that.filePicName = file.name;
      examCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {}
          },
          function(err, data) {
            if (data) {
              that.addForm.title = res.data.key;
              // console.log(that.addForm.title)
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = e => {
                that.pic_url = e.target["result"]; // 获取文件装换后的Base64位文件
                // console.log(that.pic_url)
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击选择视频文件按钮
    selectVideo() {
      this.$refs.video.click();
    },

    // 选择了视频文件
    selectVideoFiles() {
      let file = this.$refs.video.files[0];
      if (this.$refs.video.files.length == 0) {
        this.addForm.title = "";
        return;
      }
      this.fileVideoName = file.name;
      // console.log(this.fileVideoName)
      // if (file.size / 1024 / 1024 > 30) {
      //   this.$message.error("音频不能大于 30M ");
      //   return;
      // }
      examCosToken({ suffix: file.name }).then(res => {
        this.CosObj = res.data;
        var that = this;
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: that.CosObj.Bucket,
            Region: that.CosObj.Region,
            Key: that.CosObj.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.videoProgress = progressData.percent * 100;
              that.addForm.title = that.CosObj.key;
              // console.log(that.addForm.title);
              that.video_url = "";
            }
          },
          function(err, data) {
            console.log(err || data);
          }
        );
      });
    }
  }
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}

.tip_nmae ::v-deep .ql-container {
  min-height: 50px;
}

.tip_nmae ::v-deep .ql-editor {
  min-height: 50px;
}

::v-deep .ql-editor {
  min-height: 300px;
}
::v-deep .ql-container {
  min-height: 300px;
}
.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
  display: flex;
  .add_item {
    flex: 1;
    display: flex;
  }
  .add_btn {
    width: 100px;
  }
}

.add_box_audio {
  margin-bottom: 20px;
  display: flex;
  .add_item {
    display: flex;
    .add_option {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
  .add_btn {
    width: 100px;
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
}

.options_arr {
  display: flex;
  background-color: #cccbce;
  .options_item {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #fff;
    color: #323232;
    cursor: pointer;
    .el-button {
      margin-left: 5px;
    }
  }
  .select_options {
    background-color: #37a58c;
    color: #fff;
  }
  .addoptions {
    margin-left: 100px;
  }
}

.title_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.flex_box {
  width: 100%;
  display: flex;
}
.flex-left {
  margin: 0 20px;
  width: 25%;
  height: 750px;
  height: auto;
  overflow-y: auto;
}
.flex-right {
  width: 75%;
  height: 750px;
  // height: auto;
  overflow-y: auto;
}
</style>
