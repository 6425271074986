import { render, staticRenderFns } from "./questionbank_management.vue?vue&type=template&id=de46fea4&scoped=true"
import script from "./questionbank_management.vue?vue&type=script&lang=js"
export * from "./questionbank_management.vue?vue&type=script&lang=js"
import style0 from "./questionbank_management.vue?vue&type=style&index=0&id=de46fea4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de46fea4",
  null
  
)

export default component.exports